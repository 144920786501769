.modalBold {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0121em;
    color: #4D5D68;
}

.modalText {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0121em;
    color: #4D5D68;
}

.modalBoldText {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 700;
    line-height: 32.5px;
    letter-spacing: 0.0121em;
    color: #4D5D68;
}

.modalPurpleText {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.21%;
    letter-spacing: 0.0121em;
    color: #5D5FEF;
}

.modalObservacion {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0121em;
    color: #79858E;
}

.observacionBold {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0121em;
    color: #364855;
}

.contador {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-left: 16px;
    padding-right: 16px;
}

.textoContador {
    font-family: "Interstate";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.0121em;
    color: #909AA1;
}

.titleList {
    font-family: "Interstate";
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.0121em;
    color: #203442;
}

.purpleTitle {
    font-family: "Interstate";
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.0121em;
    color: #392F95;
    display: inline;
}

.purpleLittle {
    font-family: "Interstate";
    font-size: 14px !important;
    font-weight: 400;
    line-height: 13.87px;
    letter-spacing: 0.0121em;
    color: #392F95;
    display: block;
}



.blackType {
    font-family: "Interstate";
    display: inline;
    font-size: 14px;
    font-weight: 400;
    line-height: 13.87px;
    letter-spacing: 1.21%;
    color: #203442;
}

.blackTypeBold {
    font-family: "Interstate";
    display: inline;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 17.65px;
    letter-spacing: 1.21%;
    color: #203442;
}

.grayText {
    font-family: "Interstate";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0121em;
    color: #63717B;
}