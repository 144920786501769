@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://use.typekit.net/qju1gbn.css");

@font-face {
  font-family: 'Interstate';
  src: url('../src/assets/fonts/Interstate-Regular.otf');
  font-style: normal;
  font-weight: bolder;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #F2F5F9;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPickersDay-root {
  border: 1px solid #E9EBEC !important;
  border-radius: 4px !important;
}

.MuiPickersDay-root:hover {
  color: #392F95 !important;
  background-color: #DCD9F7 !important;
}

.white-color-svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(125deg) brightness(106%) contrast(100%);
}

.warning-color-svg {
  filter: brightness(0) saturate(100%) invert(63%) sepia(77%) saturate(560%) hue-rotate(358deg) brightness(100%) contrast(105%);
}

.content {
  padding: 24px;
  width: 90%;
  margin: auto;
}

.span-text {
  color: #4D5D68;
  font-weight: bold;
}

.display-none {
  display: none !important;
}

.MuiListItemText-root.linkMenu {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0120588em;
  color: #67737C;
  display: inline-flex;
}


.headerTableIzquierda,
.tableBodyIzquierda {
  position: sticky;
  position: -webkit-sticky;
  left: -1px;
  z-index: 99;
  width: 113px;
  height: 48px;
  font-size: 14px;
  color: #364855;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 113px;
  background-color: white;
  min-width: 113px;
}


th.headerTableIzquierda {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(124 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.tableBodyIzquierda {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='400' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(124 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyIzquierda:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(124 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' fill='%23f6f6f6'/%3E%3C/g%3E%3C/svg%3E");
}

.headerTableAcciones {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 172px;
  background-color: #f8f9f9;
  min-width: 172px;
  position: sticky;
  right: -1px;
  z-index: 99;
  display: inline-flex;
  padding: 16px 30px !important;
}

.headerAcciones {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #f8f9f9;
  min-width: 125px;
  position: sticky;
  right: -1px;
  z-index: 99;
  display: inline-flex;
  padding: 16px 30px !important;
}



th.headerTableAcciones {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.headerTableAsignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.headerTableReasignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.headerTableEspecial {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.headerFirstSticky {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

td.headerTableAcciones {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

.tableBodyAcciones {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #FFFFFF;
  min-width: 125px;
  position: sticky;
  right: -1px;
  z-index: 9;
  display: inline-flex;
  padding: 16px 30px !important;
}

.tableBodyAsignar {
  position: sticky;
  right: 149px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 164px;
  background-color: #FFFFFF;
  padding-left: 30px !important;
  height: 48px;
}

.tableBodyReasignar {
  position: sticky;
  right: 240px;
  min-width: 120px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #FFFFFF;
  padding-left: 30px !important;
  height: 48px;
}

.tableBodyEspecial {
  position: sticky;
  right: 120px;
  min-width: 120px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #FFFFFF;
  padding-left: 30px !important;
  height: 48px;
}


.headerTableAsignar {
  position: sticky;
  right: 149px;
  width: 164px;
  height: 48px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #fafafa;
  font-size: 14px;
  color: #364855;
  padding-left: 30px !important;
}

.headerTableReasignar {
  position: sticky;
  right: 240px;
  min-width: 120px;
  height: 48px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #fafafa;
  font-size: 14px;
  color: #364855;
  padding-left: 30px !important;
}

.headerTableEspecial {
  position: sticky;
  right: 120px;
  min-width: 120px;
  height: 48px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #fafafa;
  font-size: 14px;
  color: #364855;
  padding-left: 30px !important;
}


.headerFirstSticky {
  position: sticky;
  right: -1px;
  width: 164px;
  height: 48px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 150px;
  background-color: #fafafa;
  font-size: 14px;
  color: #364855;
  padding-left: 30px !important;
}

 

th.tableBodyAcciones {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.tableBodyAsignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.tableBodyReasignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

th.tableBodyEspecial {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23f8f9f9'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyAcciones {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyAsignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='160' height='1' viewBox='0 0 160 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='160' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyReasignar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyEspecial {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='140' height='1' viewBox='0 0 140 1'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0.5' y1='1' x2='0.5' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23c9cfe3' stop-opacity='0.345'/%3E%3Cstop offset='1' stop-color='%23c9cfe3' stop-opacity='0'/%3E%3C/linearGradient%3E%3CclipPath id='c'%3E%3Crect width='140' height='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='b' clip-path='url(%23c)'%3E%3Crect width='1' height='16' transform='translate(0 1) rotate(-90)' fill='url(%23a)'/%3E%3Crect width='124' height='1' transform='translate(16)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
}

td.tableBodyAcciones:hover {
  background-color: white !important;
}

th.escritorioTrabajo {
  height: 20px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

td.escritorioTrabajo {
  height: 20px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

th.headerSort {
  background-color: #F8F9F9;
  border-right: 1px solid #D2D6D9;
}

th.headerSort2 {
  background-color: #F8F9F9;
  border-right: 1px solid #D2D6D9;
  height: 58px;
}

th.activeSort {
  color: #01736C !important;
  font-weight: 700 !important;
  background-color: #CCEDEB;
}

.MuiButtonBase-root.MuiTab-root.tabGreen {
  margin-right: 0;
  margin-bottom: 2px;
  background: none;
  border: none;
  color: #79858E;
  font-family: "Interstate";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0121em;
  text-align: center;
  padding: 10px 20px;
  border-radius: 0;
}

.MuiButtonBase-root.MuiTab-root.tabGreen:hover {
  border-radius: 0px;
  background: none;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected.tabGreen:hover {
  border-radius: 0px;
  background: none;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected.tabGreen {
  margin-right: 0;
  margin-bottom: 2px;
  border-radius: 0;
  background: none !important;
  border-bottom: 2px solid #01A49A;
  color: #01736C;
  font-family: "Interstate";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.0121em;
  text-align: center;
  padding: 10px 20px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected.tabGreen:end {
  border-bottom: 2px solid #01A49A;
}

.errorBox {
  width: fit-content;
  background: #FFEBEE;
  border: 1px solid #E57373;
  border-radius: 8px;
  padding: 4px 10px 2px 10px;
  justify-content: start;
  align-items: flex-start;
  display: flex;
}

.boxInfo {
  background: #F1F8E9;
  border: 1px solid #7CB342;
  border-radius: 8px;
  padding: 10px 14px;
  justify-content: start;
  align-items: flex-start;
  display: flex;
}

.textBoldBox {
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #203442;
}

.titleInfoBox {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #203442;
}

.subTextInfoBox {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #364855;
}

.MuiChip-root.MuiChip-outlined.MuiChip-deletable.MuiChip-deletableColorDefault {
  background-color: #EEECFB !important;
  border: 1px solid #978EE6 !important;
  border-radius: 8px !important;
  font-family: 'Interstate' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.0120588em !important;
  color: #203442 !important;
  padding-right: 8px !important;
}

.filtroFont {
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.0120588em;
  color: #4D5D68;
}

.TituloResultadoTabla {
  font-size: 28px;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #4C4C4C;
}

.subtituloResultadoTabla {
  font-size: 16px;
}

.contenedor-loader {
  padding: auto;
  text-align: center;
}

.modal-loader {
  text-align: center;
}

.loader {
  border: 5px solid #CCEDEB;
  margin-left: 50%;
  margin-top: 10%;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  animation-name: stretch;
  animation-duration: 0.7s;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(0.5);
    color: #01A49A;
    border-radius: 100%;
  }

  50% {
    background-color: #01A49A;
  }

  100% {
    transform: scale(1.5);
    background-color: #01A49A;
  }
}

.modal-loader>.custom-loader-visa {
  margin-left: 48.5%;
  border: 5px solid #CCEDEB;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-bottom: 26px;
  animation-name: stretch;
  animation-duration: 0.7s;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

.contenedor-loader>.custom-loader-visa {
  margin-left: 48.5%;
  border: 5px solid #CCEDEB;
  border-radius: 100%;
  margin-top: 6%;
  width: 16px;
  height: 16px;
  margin-bottom: 26px;
  animation-name: stretch;
  animation-duration: 0.7s;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

.headerTable {
  background-color: #F8F9F9;
  font-family: "Interstate";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0121em;
}

.MuiTypography-root.rutTable {
  font-family: "Interstate";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0121em;
  color: #364855;
}

.MuiTypography-root.nameTable {
  font-family: "Interstate";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.0121em;
  color: #79858E;

}

.menuForm.MuiButtonBase-root-MuiMenuItem-root {
  display: none;
}

.menuForm .MuiPaper-root .MuiMenu-list {
  width: auto;
}


.menuForm li {
  border-top: 1px solid #D2D6D9;
  color: #01736C;
  cursor: auto;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.0120588em;
}


.menuForm li:first-child {
  border-top: none;
}

.menuForm .MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.menuForm .MuiPopover-paper {
  border-radius: 8px !important;
}

.menuForm .MuiMenu-list li {
  width: 100% !important;
  padding: 12px 16px !important;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0120588em;
  color: #01736C;
  border-bottom: 1px solid #D2D6D9;
}

.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.MuiButtonBase-root.Mui-disabled.menuForm {
  width: 100% !important;
  background-color: #E9EBEC !important;
  padding: 12px 16px !important;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0120588em;
  color: #01736C !important;
  border-bottom: 1px solid #D2D6D9;
}

.menuForm .MuiMenu-list li:hover {
  background-color: #E6F6F5;
  color: #01736C;

}

.menuForm.MuiMenu-list li:last-child {
  border-bottom: none;
}

.menuForm.MuiMenu-list li .col-6 {
  left: 69%;
}

.menuForm.MuiPaper-elevation1 .MuiMenu-list {
  width: 100% !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.FirstMenu::after {
  color: #909AA1 !important;
  font-family: "Interstate";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0121em;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.FirstMenu {
  max-width: 40% !important;
}

.MuiTypography-root.AlertLabel {
  display: inline;
  font-family: 'Interstate';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.0121em;
  color: #203442;
}

.MuiTypography-root.AlertLabelBold {
  display: inline;
  font-family: 'Interstate';
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.0121em;
  color: #203442;
}

.brGreen {
  border-right: solid 1px #E6F6F5;
}

.blackTypeBold {
  font-family: "Interstate";
  display: inline;
  font-size: 16px;
  font-weight: 700;
  line-height: 17.65px;
  letter-spacing: 1.21%;
  color: #203442;
}

.widthMax {
  min-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inlineClass {
  display: inline !important;
  max-width: 170px !important;
}

.spinnerButton {
  padding: 2px;
  border: 2px solid rgba(0, 0, 0, 0);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-left-color: #FFFFFF;
  border-right-color: #FFFFFF;
  border-top-color: #FFFFFF;
  animation: spin 1s ease infinite;
}

.spinnerButtonGreen {
  padding: 2px;
  border: 2px solid rgba(0, 0, 0, 0);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-left-color: #01736C;
  border-right-color: #01736C;
  border-top-color: #01736C;
  animation: spin 1s ease infinite;
}

.purpleBox {
  background-color: #EEECFB;
  padding: 8px 16px;
  border-radius: 4px;
}

.grayScroll::-webkit-scrollbar {
  width: 8px;
  max-height: 10px !important;
}

.grayScroll::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;

}

.grayScroll::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;

}

.grayScroll::-webkit-scrollbar-thumb:hover {
  background: #68747c;
}

.headAccordion {
  padding: 0px !important;
  height: 48px !important;
  font-size: 14px !important;
  min-width: 125px;
}

.headerEliminar {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 125px;
  background-color: #f8f9f9;
  min-width: 75px;
  position: sticky;
  right: -1px;
  z-index: 99;
  display: inline-flex;
  font-size: 14px !important;
  height: 48px !important;
}

.headerAction{
  min-width: 75px !important;
}
.columnAccion2 {
  right: 80.4px !important;
}
.columnAccionAuth {
  right: -1px !important;
}
.BodyAcciones {
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 75px;
  background-color: #FFFFFF;
  min-width: 75px;
  position: sticky;
  right: -1px;
  z-index: 9;
  display: inline-flex;
  font-size: 14px !important;
  height: 48px !important;
}

.headerCheckSticky {
  position: sticky;
  width: 85px;
  height: 48px;
  left: 0px;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 85px;
  background-color: #fafafa;
  font-size: 14px;
  color: #364855;
}

.BodyCheckSticky {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  z-index: 9;
  width: 85px;
  height: 48px;
  font-size: 14px;
  color: #364855;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 85px;
  background-color: white;
  min-width: 85px;
}
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded  {
  background-color: #E6F6F5 !important;
  border-radius: 8px 8px 0px 0px !important;
}

.borderTable{
  border-left: 1px solid #D2D6D9;
}

.menuFormAlt .MuiMenu-list li:hover {
  background-color: #E6F6F5;
  color: #01736C;

}

.menuFormAlt .MuiPopover-paper {
  border-radius: 8px !important;
}

 
.menuFormAlt li {
  border-top: 1px solid #D2D6D9;
  color: #01736C;
  cursor: auto;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.0120588em;
}

 
.menuFormAlt .MuiMenu-list li {
  width: 100% !important;
  padding: 12px 16px !important;
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0120588em;
  color: #364855; 
}

.MuiButton-root.MuiLoadingButton-root.inheritCarga:hover{
color: #01736C !important;
border: none !important;
}

.MuiButton-root.MuiLoadingButton-root.inheritCarga:active{
  box-shadow: none !important;
  border: none !important;
  color: #01736C !important;
  background-color: transparent !important;
  }