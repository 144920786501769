.MuiTypography-root.AltTitulo1 {
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0120588em;
  color: #203442;
}

.MuiTypography-root.AltTitulo2 {
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0120588em;
  color: #203442;
  margin-bottom: 16px;
}

.MuiChip-root.chipSelected {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #FFFFFF;
  background: #5D5FEF;
  border-radius: 100px;
  border-color: #5D5FEF;
}

.MuiChip-root.chipSelected:hover {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #ffffff;
  background: #5D5FEF;
  border-radius: 100px;
  border-color: #5D5FEF;
}

.MuiChip-root.chipNotSelect {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #5D5FEF;
  background: #FFFFFF;
  border-radius: 100px;
  border-color: #5D5FEF;
}

.MuiChip-root.chipNotSelect:hover {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0120588em;
  color: #5D5FEF;
  background: #FFFFFF;
  border-radius: 100px;
  border-color: #5D5FEF;
}


.Mui-disabled .MuiAutocomplete-inputRoot {
  color: '#67737C';
  background-color: '#E9EBEC';
}

.textLabel4 {
  font-family: 'Interstate';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0120588em;
  color: #203442;
}

.labelForm {
  margin-bottom: 5px;
  font-family: interstate;
}